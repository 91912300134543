<template>
    <v-card class="mx-auto mt-12" color="#26c6da" dark width="400">
        <v-card-title>
            <v-icon large left> cloud_download </v-icon>
            <span class="text-h6 font-weight-light">Busca de resultados</span>
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold">
            Digite o código de acesso:
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
                <v-text-field v-model="code" :counter="10" :rules="r" label="Código de acesso" @keyup="uppercase" class="code mb-2" required />
                <v-btn :disabled="!valid" color="success" style="width: 100%" @click="validate">{{ $t("Buscar Resultado") }}</v-btn>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                    <v-img
                        class="elevation-6"
                        alt=""
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                    ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Labvida</v-list-item-title>
                </v-list-item-content>

                <v-row align="center" justify="end">
                    <v-icon class="mr-1"> mdi-heart </v-icon>
                    <span class="subheading mr-2">256</span>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1"> mdi-share-variant </v-icon>
                    <span class="subheading">45</span>
                </v-row>
            </v-list-item>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "Attendant",
    data: function () {
        return {
            id: null,
            valid: false,
            code: "",
            r: [(v) => (v && v.length == 10) || "Código de acesso deve ter 10 caracteres"],
        };
    },

    methods: {
        uppercase() {
            this.code = this.code.toUpperCase();
        },

        validate() {
            if (this.$refs.form.validate()) {
                this.$http
                    .post(`results/${this.id}`, { code: this.code })
                    .then((result) => this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Ok."))
                    .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao buscar."));
            }
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
        }
    },
};
</script>
